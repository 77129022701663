<template>
  <div class="row">
    <div class="col-xs-12 margin-bottom">
      <div class="col-xs-12">
        <button
          class="btn btn-default pull-right"
          @click="print"
        >
          <i class="fa fa-print" /> {{ $t('label.print') }}
        </button>
      </div>
    </div>
    <div class="col-xs-12">
      <block-iframe :model_uuid="model_uuid" />
    </div>
  </div>
</template>

<script>
import BlockIframe from "@/components/BlockIframe"

export default {
  name: "LetterCertificateOfOccupancy",
  components: {BlockIframe},
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    model_uuid: {
      type: String,
      required: true
    }
  },
  methods: {
    print(e) {
      e.preventDefault()
      window.open(`${this.baseUrl}/certificates/occupancy/print/${this.model_uuid}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
